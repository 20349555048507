.x-grid-panel {
	.x-panel-bbar .x-toolbar {
		background: transparent;
		border: 0;

		.xtb-sep {
			background: transparent;
			border: 0;
		}
	}

	.x-panel-body {
		background: transparent;
		border: 0;
	}
}

.x-grid3 {
	background: transparent;
}

.x-grid3-header {
	background: #eeeff1;
}

.x-grid3-header-offset {
	padding: 0px 2px;
}

.x-grid3-hd-row td {
	border: 0;
	color: #707070;
	font-size: 9px;
	font-weight: bold;
	text-transform: uppercase;
}

td {
	&.sort-desc .x-grid3-hd-inner, &.sort-asc .x-grid3-hd-inner {
		background: transparent;
		color: #1a7da1;
	}
}

.x-grid3-hd-inner {
	&.x-grid3-hd-balance, &.x-grid3-hd-debits, &.x-grid3-hd-credits {
		padding-right: 5px !important;
	}
}

.x-grid3-hd-btn {
	color: #1a7da1;
}

.x-grid3-hd-over .x-grid3-hd-btn {
	background: transparent;
}

.x-grid3-row {
	background: transparent;
	border: 0;
	color: #333333;
	padding: 4px 2px;
}

.x-grid3-row-alt {
	background: #b9ced6;
	border: 0;
}

.x-grid3-body {
	.x-grid3-td-expander {
		background: transparent;
	}

	.x-grid3-row-expander {
		/* icon */
	}
}

.x-grid3-scroller {
	overflow: hidden;
}

.x-grid3-row-body-tr p {
	padding-left: 190px;
}

table.x-grid3-row-table td.x-grid3-cell-selected {
	background-color: transparent !important;
}

.x-grid-panel td {
	&.x-toolbar-left > table {
		float: right;
		border-collapse: separate;
		border: 1px solid #c6c6c6;
		background: #ededed;
		background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#F5F5F5), to(#ededed));
		background: -moz-linear-gradient(to bottom, #F5F5F5, #ededed);
		border-radius: 3px;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
	}

	&.x-toolbar-right table.x-btn {
		border-collapse: separate;
		border: 1px solid #c6c6c6;
		background: #ededed;
		background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#F5F5F5), to(#ededed));
		background: -moz-linear-gradient(to bottom, #F5F5F5, #ededed);
		border-radius: 3px;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
	}
}

.x-grid3-row {
	&.is_pending {
		font-style: italic;
		color: #707070;
	}

	&.has_alert {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/alert.png");
		background-position: 99% center;
		background-repeat: no-repeat;
	}

	&.split .x-grid3-col-category {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.x-grid3-body span.split {
	display: block;
	text-indent: -9999px;
	background: url("https://assets.orb.alkamitech.com/production/assets/global/images/split-txn-2.png") no-repeat;
	width: 71px;
	height: 21px;
	cursor: pointer;
}

.x-grid-editor .x-form-field-wrap {
	.x-form-trigger {
		top: 2px;
		right: 20px;
	}

	.x-form-text {
		width: 88px !important;
		padding: 3px 23px 3px 6px;
		border-radius: 3px;
		box-shadow: inset 1px 1px 3px #BBB;
		border: 1px solid #B8BABA;
	}
}

.x-grid-panel {
	.x-toolbar-left table td.x-btn-mc, .x-toolbar-right table.x-btn td.x-btn-mc {
		padding: 3px 0 !important;
	}

	.x-toolbar {
		div.xtb-text, .x-btn button {
			color: #707070;
			font-weight: bold;
			font-size: 12px;
			text-shadow: 0 1px 1px #fff;
		}
	}
}

input.x-tbar-page-number {
	color: #707070;
	width: 20px;
	text-align: center !important;
	margin-right: 3px;
}

.x-toolbar {
	.x-btn-tl, .x-btn-tr, .x-btn-tc, .x-btn-ml, .x-btn-mr, .x-btn-mc, .x-btn-bl, .x-btn-br, .x-btn-bc {
		background: transparent;
	}
}

.x-tbar-page-prev, .x-item-disabled .x-tbar-page-prev {
	background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/vendor/extjs/default/grid/arrow-left.png") !important;
}

.x-tbar-page-next, .x-item-disabled .x-tbar-page-next {
	background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/vendor/extjs/default/grid/arrow-right.png") !important;
}

.x-tbar-page-first, .x-item-disabled .x-tbar-page-first {
	background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/vendor/extjs/default/grid/double-arrow-left.png") !important;
}

.x-tbar-page-last, .x-item-disabled .x-tbar-page-last {
	background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/vendor/extjs/default/grid/double-arrow-right.png") !important;
}

.x-tbar-loading {
	background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/vendor/extjs/default/grid/refresh.png") !important;
}

.x-btn-text-icon .x-btn-icon-small-left .x-btn-text.arrow-down {
	padding-left: 0;
}

.x-grid-panel {
	.x-toolbar-left-row {
		padding: 0 6px;
		display: block;
	}

	.x-toolbar-right {
		padding-left: 10px;
	}

	.x-toolbar .x-toolbar-right-row .x-btn-icon-small-left .x-btn-mc em.x-btn-split {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/vendor/extjs/default/grid/arrow-down.png");
		padding: 0 20px 0 6px;
	}
}

.x-grid3-row-expander, .x-grid3-row-expanded .x-grid3-row-expander {
	margin: 4px;
	height: 11px;
	width: 11px;
	font-size: 10px;
	position: relative;
	background: transparent;
}

.x-grid3-body {
	.x-grid3-td-checker {
		background: none;
	}

	.x-grid3-row-selected {
		.x-grid3-td-numberer, .x-grid3-td-checker, .x-grid3-td-expander {
			background: none;
		}
	}

	.x-grid3-row-checker, .x-grid3-hd-checker {
		background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/vendor/extjs/default/grid/row-check-sprite.png");
	}
}

/*** page resizer ***/
/** .x-menu-list-item > .x-menu-item, .x-menu-item-active > .x-menu-item, .x-menu-item-checked > .x-menu-item **/

.x-cycle-menu {
	.x-menu-list-item {
		padding: 1px !important;
		margin: 0 !important;
		border: none !important;
		background: transparent !important;

		.x-menu-item {
			margin: 0 !important;
			border: none !important;
			background: transparent !important;
		}
	}

	.x-menu-item-active, .x-menu-item-checked {
		margin: 0 !important;
		border: none !important;
		background: transparent !important;

		.x-menu-item {
			margin: 0 !important;
			border: none !important;
			background: transparent !important;
		}
	}

	.x-menu-item-active {
		padding: 0 !important;
		border: 1px solid #B8BABA !important;
		background: #ddd !important;
	}
}

.x-menu-item-checked .x-menu-group-item .x-menu-item-icon {
	background: url("https://assets.orb.alkamitech.com/production/assets/global/images/vendor/jquery/ui/ui-icons_454545_256x240.png") -64px -144px no-repeat;
}

.x-grid3-row td, .x-grid3-summary-row td {
	padding: 0;
	vertical-align: middle;
}

.x-grid3-row .bar {
	display: block;
	height: 25px;
	width: 7px;
	border-radius: 2px 2px 2px 2px;
}

.x-grid3-cell-inner, .x-grid3-hd-inner {
	padding: 3px 5px;
	cursor: pointer;
}

.x-grid3-col-description {
	cursor: pointer;
	max-height: 36px;
	line-height: 19px;
	white-space: normal;

	&:hover {
		text-decoration: underline;
	}
}